import React from "react";
import "./product-item.scss";

const Product = props => (
  <div className="product">
    <div
      className="product__img"
      style={{ backgroundImage: `url(${props.productImg})` }}
    />
    <div className="product__content">
      <img
        className="product__content__logo"
        src={props.productLogo}
        alt="itsalive logo"
      />
      <div className="product__content__title">{props.productTitle}</div>
      <div className="product__content__desc">{props.productDesc}</div>
      <div className="product__content__specs-list">
        <div className="product__content__specs-list__item">
          <div className="product__content__specs-list__item__type">Model</div>
          <div className="product__content__specs-list__item__value">
            {props.productModel}
          </div>
        </div>
        <div className="product__content__specs-list__item">
          <div className="product__content__specs-list__item__type">
            Audience
          </div>
          <div className="product__content__specs-list__item__value">
            {props.productAudience}
          </div>
        </div>
        <div className="product__content__specs-list__item">
          <div className="product__content__specs-list__item__type">Since</div>
          <div className="product__content__specs-list__item__value">
            {props.productDate}
          </div>
        </div>
      </div>
      <a
        href={props.productButtonUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="product__content__button"
      >
        {props.productButton}
      </a>
    </div>
  </div>
);

export default Product;
